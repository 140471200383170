import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { GthEnvironment } from '@gth-legacy/tokens/environment-tokens';
import { Location } from '@index/interfaces';

@Component({
  selector: 'gth-cal-link',
  templateUrl: './cal-link.component.html',
  styleUrls: ['./cal-link.component.scss'],
  imports: [MatMenuModule, MatIconModule, MatButtonModule],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalLinkComponent {
  config = input<GthEnvironment>();
  title = input.required<string>();
  location = input.required<Location>();
  description = input.required<string>();
  dateStart = input.required<Date>();
  dateEnd = input.required<Date>();
  eventId = input.required<string>();

  constructor() {}

  openGoogleLink() {
    const eventTitle = encodeURIComponent(this.title());
    const eventLocation = encodeURIComponent(this.location().formattedAddress);
    const eventDetails = encodeURIComponent(this.description());

    // Format start and end dates in UTC format
    const startDate = new Date(this.dateStart()).toISOString().replace(/-|:|\.\d+/g, '');
    const endDate = new Date(this.dateEnd()).toISOString().replace(/-|:|\.\d+/g, '');

    const googleCalendarLink = [
      `https://calendar.google.com/calendar/u/0/r/eventedit?`,
      `text=${eventTitle}`,
      `&dates=${startDate}/${endDate}`,
      `&location=${eventLocation}`,
      `&details=${eventDetails}`,
    ].join('');

    window.open(googleCalendarLink, '_blank');
  }

  generateICalFile() {
    const formatDate = (date: Date) => date
    .toISOString()
    .replace(/-|:/g, '')
    .replace(/\.\d{3}Z/, 'Z');

    const now = formatDate(new Date());
    const startDate = formatDate(this.dateStart());
    const endDate = formatDate(this.dateEnd());

    const formattedDescription = this.description()
    .replace(/(\r\n|\n|\r)/gm, '\\n');

    const formattedLocation = this.location().formattedAddress
    .replace(/(\r\n|\n|\r)/gm, '\\n');

    const header = [
      `BEGIN:VCALENDAR`,
      `VERSION:2.0`,
      `CALSCALE:GREGORIAN`,
      `METHOD:PUBLISH`,
      `BEGIN:VEVENT`,
    ];

    const eventDetails = [
      `DTSTAMP:${now}`,
      `DTSTART:${startDate}`,
      `DTEND:${endDate}`,
      `STATUS:CONFIRMED`,
      `SUMMARY:${this.title()}`,
      `DESCRIPTION:${formattedDescription}`,
      `CLASS:PUBLIC`,
      `CREATED:${now}`,
    ];

    const locationDetails = [
      `GEO:${this.location().lat};${this.location().lng}`,
      `LOCATION:${formattedLocation}`,
      `URL:${this.config().root}/discover/games/${this.eventId()}`,
      `LAST-MODIFIED:${now}`,
      `UID:${this.eventId()}@meetup.com`,
    ];

    const footer = [
      `END:VEVENT`,
      `END:VCALENDAR`,
    ];

    const icalString = [
      ...header,
      ...eventDetails,
      ...locationDetails,
      ...footer,
    ].join('\r\n');

    const blob = new Blob([icalString], { type: 'text/calendar' });

    // Create a link element
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', `${this.title()}.ics`);

    // Append the link to the body
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Clean up
    document.body.removeChild(link);
  }
}
