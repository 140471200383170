import {
  IMAGE_CONFIG,
  JsonPipe,
  Location,
  NgOptimizedImage,
  provideImageKitLoader,
} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Inject,
  inject,
  Input,
  input,
  OnInit,
  Optional,
  Output,
  output,
  Signal,
  signal,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltip } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { GthEventItemModel, GthTeamModel, GthUserModel } from '@sentinels/models';
import { EventItemService } from '@sentinels/services/firebase/event-items.service';
import { EventJoinerService } from '@sentinels/services/firebase/event-joiner.service';
import { APP_STATE, CurrentState } from '@sentinels/state/public-api';
import { AuthDialogComponent } from '@shared/dialogs/auth-dialog/auth-dialog.component';
import { APP_ROUTES } from '@shared/helpers';
import firebase from 'firebase/compat/app';
import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { distinctUntilChanged, map, switchMap, take } from 'rxjs/operators';

// TODO circular dependency
import { EventImageService } from '../../../../../gth/src/app/features/main/services/event-image/event-image.service';
import { DEFAULT_CURRENT_USER, GUEST_PROFILE_ID, StripeService } from '../../services';
import { EventsService } from '../../services/events.service';
import { GTH_ENVIRONMENT, GthEnvironment } from '../../tokens/environment-tokens';
import {
  ConfirmDialogComponent,
  ConfirmDialogContract,
} from '../confirm-dialog/confirm-dialog.component';
import { selectEventInfoState } from '../event-info/state/selectors';
import { TabComponent } from './components/event-main-info/components/tab-group/components/tab/tab.component';
import { TabBodyComponent } from './components/event-main-info/components/tab-group/components/tab-body/tab-body.component';
import { TabHeaderComponent } from './components/event-main-info/components/tab-group/components/tab-header/tab-header.component';
import { TabGroupComponent } from './components/event-main-info/components/tab-group/tab-group.component';
import { EventMainInfoComponent } from './components/event-main-info/event-main-info.component';
import { RsvpBarComponent } from './components/rsvp-bar/rsvp-bar.component';
import { JoinEventOptions } from './components/rsvp-steps/rsvp-steps.component';
import { ChatTabComponent } from './components/tabs/chat-tab/chat-tab.component';
import { LocationTabComponent } from './components/tabs/location-tab/location-tab.component';
import { MoreInfoTabComponent } from './components/tabs/more-info-tab/more-info-tab.component';
import { RsvpTabComponent } from './components/tabs/rsvp-tab/rsvp-tab.component';
import { EventInfoState } from './state/state';
import { EventInfoViewModel } from './view-models/event-info-model';

export interface EventInfoDisplayConfig {
  map: boolean;
  type: boolean;
  description: boolean;
  date: boolean;
  location: boolean;
  gameType: boolean;
  cost: boolean;
  participants: boolean;
  actions: boolean;
  share: boolean;
  manageAndEditButton?: boolean;
  cancelButton?: boolean;
}

@Component({
  selector: 'gth-event-info',
  templateUrl: './event-info.component.html',
  styleUrls: ['./event-info.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    EventMainInfoComponent,
    TabGroupComponent,
    TabBodyComponent,
    TabHeaderComponent,
    TabComponent,
    MoreInfoTabComponent,
    LocationTabComponent,
    ChatTabComponent,
    RsvpTabComponent,
    NgOptimizedImage,
    RsvpBarComponent,
    JsonPipe,
    MatTooltip,
    NgxSkeletonLoaderModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MatDialogRef,
      useValue: {},
    },
    {
      provide: IMAGE_CONFIG,
      useValue: {
        placeholderResolution: 50,
      },
    },
    provideImageKitLoader('https://ik.imagekit.io/67ng46hjy'),
  ],
})
export class EventInfoComponent implements OnInit {
  user = input<GthUserModel | null>(null);
  preview = input<boolean>(false);
  eventInfo = input<GthEventItemModel>(null);
  isFavorited = input(false);
  checkoutSessionLoading = input<boolean>();

  @Input()
  platform: 'gth' | 'meh' = 'gth';

  @Input()
  shouldShow: EventInfoDisplayConfig = {
    map: true,
    type: true,
    description: true,
    date: true,
    location: true,
    gameType: true,
    cost: true,
    participants: true,
    actions: true,
    share: true,
  };

  @Output()
  joinEvent = new EventEmitter<JoinEventOptions>();

  @Output()
  editEvent = new EventEmitter<GthEventItemModel>();

  @Output()
  manageEvent = new EventEmitter<GthEventItemModel>();

  @Output()
  declineInvite = new EventEmitter<GthEventItemModel>();

  @Output()
  cancelEvent = new EventEmitter<GthEventItemModel>();

  @Output()
  leaveEvent = new EventEmitter<GthEventItemModel>();

  unPreviewEvent = output();
  saveEvent = output();
  toggleFavoriteEvent = output<GthEventItemModel>();

  currentRoute: string;
  APP_ROUTES = APP_ROUTES;
  url = computed(
    () =>
      `${this.config.root}${APP_ROUTES.DiscoverGames}/${this.eventViewModelSignal().event.id}`,
  );
  isGthPlatform = this.platform === 'gth';
  hasViewLogged = false;
  private eventViewModelSignal = signal<EventInfoViewModel>(new EventInfoViewModel());
  eventViewModel = this.eventViewModelSignal.asReadonly();
  isEventReady = computed(() => {
    const eventViewModel = this.eventViewModel();
    return !!eventViewModel && !eventViewModel.isLoading;
  });

  dateParam = toSignal(
    this.activatedRoute.queryParams.pipe(map((params) => params['date'])).pipe(
      map((date) => {
        if (!date) return null;
        return new Date(date);
      }),
    ),
  );

  eventDateStart = computed(() => {
    if (!this.dateParam() || !this.eventViewModel()?.event.recurring?.recurring) {
      return this.eventViewModel().event.dateStart;
    }
    return this.dateParam();
  });

  eventDateEnd = computed(() => {
    if (!this.dateParam() || !this.eventViewModel()?.event.recurring?.recurring) {
      return this.eventViewModel().event.dateEnd;
    }
    const endDate = new Date(this.eventInfo()?.dateEnd);
    endDate.setDate(this.eventDateStart().getDate());
    return endDate;
  });

  participants = computed(() => {
    if (!this.dateParam()) return this.eventViewModel().joiners;

    return this.eventViewModel().joiners.filter((joiner) => {
      const joinerDate = new Date(
        (joiner.recurringDate as unknown as firebase.firestore.Timestamp)?.toDate(),
      );
      return joinerDate.toDateString() === this.dateParam().toDateString();
    });
  });

  participantCount = computed(() => {
    return this.participants().length;
  });

  isGuestProfile = computed(() => {
    const user = this.user();
    if (!user) return true;
    return user.uid == GUEST_PROFILE_ID || user.uid === DEFAULT_CURRENT_USER.uid;
  });

  public bannerImg = toSignal(
    toObservable(this.eventViewModel).pipe(
      switchMap((event) => {
        return this.eventImageService.getEventImgObj$(event.eventType, event.banner);
      }),
    ),
  );

  private eventImageService = inject(EventImageService);
  private destroyRef = inject(DestroyRef);

  constructor(
    @Inject(GTH_ENVIRONMENT)
    public readonly config: GthEnvironment,
    @Optional()
    private dialogRef: MatDialogRef<EventInfoComponent>,
    private eventJoinersService: EventJoinerService,
    private eventsService: EventsService,
    private stripeService: StripeService,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router,
    private store: Store<APP_STATE>,
    private location: Location,
    private eventsItemsService: EventItemService,
  ) {
    gsap.registerPlugin(ScrollToPlugin);
  }

  ngOnInit() {
    this.currentRoute = this.router.url;

    if (this.preview()) {
      const eventInfoState: EventInfoState = {
        event: this.eventInfo(),
        state: CurrentState.Success,
        joinersUsers: {
          users: [],
          joiners: [],
        },
        teamId: '',
        isBottomCollapsed: false,
      };
      this.eventViewModelSignal.set(new EventInfoViewModel(eventInfoState));
    } else {
      this.setUpStore$();
    }
  }

  mainInfoRef = viewChild(EventMainInfoComponent, { read: ElementRef });
  tabGroup = viewChild(TabGroupComponent);
  bodyRef: Signal<ElementRef<HTMLElement>> = viewChild(TabBodyComponent, { read: ElementRef });
  isBottomNavCollapsed = input<boolean>(true);

  hasRSVPed = computed(() => {
    if (!this.dateParam()) {
      return this.eventViewModel().joiners.some((p) => p.player === this.user()?.uid);
    }

    return this.eventViewModel().joiners.some((p) => {
      const joinerDate = new Date(
        (p.recurringDate as unknown as firebase.firestore.Timestamp)?.toDate(),
      );
      return joinerDate.toDateString() === this.dateParam().toDateString();
    });
  });

  get dateStart(): Date {
    return this.eventViewModel().event.dateStart;
  }

  get dateEnd(): Date {
    return this.eventViewModel().event.dateEnd;
  }

  isEventPast() {
    const now = new Date();
    return now > this.dateEnd;
  }

  onSelectedTabChange() {
    this.scrollToEventBody();
  }

  scrollToEventBody() {
    if (typeof window === 'undefined') return;
    if (window.innerWidth > 768) return;
    if (!this.tabGroup().selectedTab()) return;

    gsap.to('.mat-sidenav-content', {
      duration: 0.35,
      // Todo(srevier): small header - 1px
      scrollTo: { y: this.bodyRef().nativeElement, offsetY: 165 },
      ease: 'power2.inOut',
    });
  }

  setUpStore$() {
    return this.store
      .select(selectEventInfoState)
      .pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
      .subscribe(async (eventInfoState) => {
        // Create Vm
        const vm = new EventInfoViewModel(eventInfoState);
        const event = eventInfoState.event;
        this.shouldShow.cost = !!vm.cost || !!vm.ticketLevels?.length;

        // setup Vm
        vm.setIsCreator(this.user());
        vm.setTeamStatus(this.user());
        // rename cancelled
        this.shouldShow.manageAndEditButton =
          this.isGthPlatform && vm.canEditEvent && !vm.cancelled;
        this.shouldShow.cancelButton = vm.canEditEvent && !vm.isGameInPast && !vm.cancelled;

        if (event && !this.hasViewLogged) {
          this.updatePageView(event);
          this.hasViewLogged = true;
          this.setUpStripe();
        }

        this.eventViewModelSignal.set(vm);
      });
  }

  async updatePageView(event: GthEventItemModel) {
    await this.eventsItemsService.updatePageView({ event: event.copy });

    this.watchQueryParams(event);
  }

  async setUpStripe() {
    if (this.isGuestProfile()) return;
    const user = this.user();
    if (!user) return;
    if (!user.stripeCustomerId) {
      const stripeCustomerId = await this.stripeService.getOrCreateCustomer();
      if (stripeCustomerId) {
        user.stripeCustomerId = stripeCustomerId;
      }
      console.debug('newly created stripe customer id', user.stripeCustomerId);
    } else {
      console.debug(`stripe customer id`, user.stripeCustomerId);
    }
  }

  onLoginClick(_event: GthEventItemModel) {
    if (this.dialogRef && this.dialogRef.close) {
      this.dialogRef.close();
    }

    const dialogRef = this.dialog.open(AuthDialogComponent, {
      width: '500px',
      height: '700px',
    });

    dialogRef.componentRef.instance.routeToload = this.currentRoute;
  }

  watchQueryParams(event: GthEventItemModel) {
    this.activatedRoute.queryParams
      .pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
      .subscribe(async (params) => {
        /** Add event join if needed */
        const joiner = params['joiner'];
        if (joiner) {
          this.eventJoinersService.approvePlayer(joiner, event.id).then((results) => {
            if (results) {
              this.snackBar.open('Successfully added User');
            }
          });
        }
        const redirectStatus = params['redirect_status'];
        const paymentIntentClientSecret = params['payment_intent_client_secret'];
        const paymentIntent = params['payment_intent'];
        if (redirectStatus && paymentIntent && paymentIntentClientSecret) {
          /** Refresh participants and remove params from url */
          this.snackBar.open('Congratulations, Payment was successful!', '', {
            duration: 5000,
          });
          /** remove query params from url */
          this.router.navigate([], {
            queryParams: {
              redirect_status: null,
              payment_intent: null,
              payment_intent_client_secret: null,
            },
            queryParamsHandling: 'merge',
          });
        }
      });
  }

  onEditButtonClick(event: GthEventItemModel, team: GthTeamModel, canEditEvent: boolean) {
    if (!canEditEvent) return;

    this.router.navigate([APP_ROUTES.CreateGame, event.id], {
      queryParams: {
        teamId: team?.id,
      },
    });
  }

  onManageButtonClick(event: GthEventItemModel, team: GthTeamModel, canEditEvent: boolean) {
    if (!canEditEvent) return;

    const queryParams: any = {
      tab: 'insights',
      teamId: team?.id,
    };

    const dateParam = this.dateParam();
    if (dateParam) {
      queryParams.date = dateParam.toDateString();
    }

    this.router.navigate([APP_ROUTES.DiscoverGames, event.id, 'manage'], {
      queryParams: queryParams,
    });

    this.manageEvent.emit(event);
  }

  async onCopyBtnClick(event: GthEventItemModel) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      id: `confirm-copy-event-${event.id}-dialog`,
      data: {
        title: `Copy Event: ${event.title}`,
        description: 'Are you sure you want to copy this event?',
        confirmButtonText: 'Copy Event',
        metadata: {
          'Event Date': event.dateStart.toDateString(),
          'Event Location': event.online ? 'Online' : event.location.formattedAddress,
        },
      } as ConfirmDialogContract,
    });

    dialogRef
      .afterClosed()
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(async (confirm?: boolean) => {
        if (!confirm) return;

        await this.router.navigate([APP_ROUTES.CreateGame], {
          queryParams: { copyEventId: event.id },
        });
      });
  }

  onCancelButtonClick(event: GthEventItemModel, canEditEvent: boolean) {
    if (!canEditEvent) return;

    this.cancelEvent.emit(event);
  }

  onJoinEvent(options: JoinEventOptions) {
    this.joinEvent.emit(options);
  }

  onLeaveEvent(event: GthEventItemModel) {
    this.leaveEvent.emit(event);
  }

  onBackClick() {
    this.location.back();
  }

  onBackButtonClick() {
    this.unPreviewEvent.emit();
  }

  onSaveButtonClick() {
    this.saveEvent.emit();
  }

  onFavoriteClick(event: GthEventItemModel) {
    this.toggleFavoriteEvent.emit(event);
  }
}
