import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CalendarComponentModule } from '@gth-legacy';
import { GthEventItemModel } from '@sentinels/models';

export interface CalendarDialogContract {
  eventItems: GthEventItemModel[];
}

@Component({
  selector: 'gth-calendar-dialog',
  standalone: true,
  imports: [
    CalendarComponentModule,
  ],
  templateUrl: './calendar-dialog.component.html',
  styleUrl: './calendar-dialog.component.scss',
})
export class CalendarDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: CalendarDialogContract) {}
}
