import { Injectable } from '@angular/core';
import {
  EventItemGuest,
  EventJoiner,
  EventJoinerStatus,
  EventJoinerUpdateRequest,
  EventRsvpStatus,
} from '@index/interfaces';
import { EventJoinerMapper } from '@index/mappers/event-joiner-mapper';
import { EventJoinerModel } from '@index/models/event-joiner';
import { DBUtil } from '@index/utils/db-utils';
import { FirestoreService } from '@sentinels/services/core/firebase.service';
import firebase from 'firebase/compat/app';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EventJoinerService extends FirestoreService<EventJoiner> {
  protected basePath = 'joiner';

  private mapper = new EventJoinerMapper();

  async joinEvent(
    joiners: EventJoiner[],
    eventItemId: string,
    rsvpStatus: EventRsvpStatus,
    guests: EventItemGuest[],
    isGuestUser = false,
    recurringDate?: Date,
  ): Promise<boolean | null> {
    return this.createEventJoiner({
      joiners,
      eventItemId,
      rsvpStatus,
      guests,
      isGuestUser,
      recurringDate,
    });
  }

  async leaveEvent(player: string, event: string): Promise<boolean | null> {
    return this.updateEventJoiner({
      player,
      event,
      status: EventJoinerStatus.Dropped,
    });
  }

  async approvePlayer(player: string, event: string): Promise<boolean | null> {
    return this.updateEventJoiner({
      player,
      event,
      status: EventJoinerStatus.Approved,
    });
  }

  async denyPlayer(player: string, event: string): Promise<boolean | null> {
    return this.updateEventJoiner({
      player,
      event,
      status: EventJoinerStatus.Denied,
    });
  }

  async updatePlayer(jsur: EventJoinerUpdateRequest): Promise<boolean | null> {
    return this.updateEventJoiner(jsur);
  }

  async createEventJoiner(data: {
    joiners: EventJoiner[],
    eventItemId: string,
    guests: EventItemGuest[],
    rsvpStatus: EventRsvpStatus,
    isGuestUser?: boolean,
    recurringDate?: Date,
  }) {
    const eventDocRef = this.firestore
      .collection(DBUtil.EventItem).doc(data.eventItemId).ref;

    /** Get Write Batch */
    const batch = this.firestore.firestore.batch();

    let userRsvpRefs;

    if (data.recurringDate) {
      userRsvpRefs = this.firestore
        .collection(DBUtil.EventItem).doc(data.eventItemId)
        .collection(DBUtil.EventJoiner)
        .ref.where('player', '==', data.joiners[0].player)
        .where('recurringDate', '==', data.recurringDate);
    } else {
      userRsvpRefs = this.firestore
        .collection(DBUtil.EventItem).doc(data.eventItemId)
        .collection(DBUtil.EventJoiner)
        .ref.where('player', '==', data.joiners[0].player);
    }

    await userRsvpRefs.get().then(async (snapshot) => {
      snapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });
    });

    for (const joiner of data.joiners) {
      const eventJoinerDocRef = eventDocRef
        .collection(DBUtil.EventJoiner).doc();

      joiner.rsvpStatus = data.rsvpStatus;

      joiner.isGuestUser =
        data.isGuestUser === undefined ? false : data.isGuestUser;

      joiner.recurringDate = data.recurringDate ?? null;

      batch.set(eventJoinerDocRef, joiner);
    }

    /** Update Event Document */
    batch.update(eventDocRef, 'updated', firebase.firestore.Timestamp.now());

    /** Commit the Batch */
    return batch.commit()
      .then(() => true);
  }

  private eventRef(eventItemId: string) {
    const collectionRef = this.firestore.collection(DBUtil.EventItem);

    return collectionRef.doc(eventItemId);
  }

  async list(eventItemId: string) {
    const models: EventJoiner[] = [];

    const eventRef = this.eventRef(eventItemId);

    const joinerRef = await lastValueFrom(
      eventRef.collection<EventJoiner>(DBUtil.EventJoiner).get(),
    );

    if (!joinerRef) return [];

    for (const doc of joinerRef.docs) {
      models.push(doc.data());
    }

    return models;
  }

  async deleteEventJoiner(eventItemId: string, userId: string) {
    const collectionRef = this.firestore.collection(DBUtil.EventItem);

    const doc = await collectionRef.doc(eventItemId);

    const joiners = await lastValueFrom(doc
      .collection(DBUtil.EventJoiner,
        (ref) => ref.where(EventJoinerModel.PLAYER, '==', userId))
      .get());

    if (!joiners) return false;

    for (const joiner of joiners.docs) {
      joiner.ref.delete();
    }

    return true;
  }

  async getJoiner(event: string, player: string) {
    const collectionRef = this.firestore.collection(DBUtil.EventItem);

    const doc = collectionRef.doc(event);

    const snap = await lastValueFrom(
      doc.collection(
        DBUtil.EventJoiner,
        (ref) => {
          return ref.where(EventJoinerModel.PLAYER, '==', player)
            .limit(1);
        }).get(),
    );

    if (!snap) return undefined;

    return snap.docs[0];
  }

  async updateEventJoiner(
    request: EventJoinerUpdateRequest,
    joiner?: firebase.firestore.DocumentReference<unknown>,
  ) {
    const item = await this.getJoiner(request.event, request.player);

    if (!item) throw new Error('Error in event-item - joiner ref item');

    const joinerRef = joiner ?? item.ref;

    if (!joinerRef) throw new Error('Error in event-item - joiner ref');


    /** Get Write Batch */
    const batch = this.firestore.firestore.batch();

    /** Update Event Document */
    const eventDocRef = this.firestore.collection(DBUtil.EventItem).doc(request.event).ref;

    batch.update(eventDocRef, 'updated', firebase.firestore.Timestamp.now());

    /** Update Event Joiner Document */
    if (request?.status) batch.update(joinerRef, 'status', request.status);
    batch.update(joinerRef, 'rsvpStatus', request.rsvpStatus ?? EventRsvpStatus.NOT_PLAYING);
    if (request?.guests) batch.update(joinerRef, 'guests', request.guests);

    /** Commit the Batch */
    return batch.commit()
      .then(() => true)
      .catch(() => false);
  }

  async getOrderedWaitlistedPlayers(event: string) {
    return (await this.eventRef(event)).collection(
      DBUtil.EventJoiner,
      (ref) => {
        return ref
          .where(EventJoinerModel.STATUS, '==', EventJoinerStatus.Waitlisted)
          .orderBy(EventJoinerModel.CREATED_AT);
      },
    );
  }

  async changeWaitlistedPlayerToApproved(event: string) {
    const ordered = await lastValueFrom(
      (await this.getOrderedWaitlistedPlayers(event)).get(),
    );

    if (!ordered) return;

    const firstApproved = ordered.docs[0];

    const model = this.mapper.fromSnapshot(firstApproved)!;

    await this.updateEventJoiner({
      event,
      player: model.player,
      status: EventJoinerStatus.Approved,
      rsvpStatus: EventRsvpStatus.PLAYING,
    });
  }
}
