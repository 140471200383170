<div class="main-info-container">
  <div class="title-container">
    <h1 class="gsap-flip">
      <mat-icon class="stuck-show" data-flip-id="icon">{{ eventTypeIcon() }}</mat-icon>
      <span>{{ title() }}</span>
    </h1>
    <h2 class="event-type stuck-hide gsap-flip">
      <!-- Todo(srevier): Implement streamline plump flat icons -->
      <mat-icon data-flip-id="icon">{{ eventTypeIcon() }}</mat-icon>
      <span>{{ type() }}</span>
    </h2>
  </div>

  <div class="address-date gsap-flip">
    <div class="address-container">
      <button
        class="icon"
        matTooltip="Get directions"
        matTooltipPosition="above"
        [matTooltipDisabled]="online()"
        [disabled]="online()"
        (click)="onMapBtnClick()"
      >
        <img class="icon" src="assets/icons/Map-Fold--Streamline-Plump.svg" loading="lazy" />
      </button>
      <div>
        <h4>{{ street() }}</h4>
        <h3>{{ city() }}</h3>
      </div>
      @if (!online()) {
        <button
          mat-icon-button
          matTooltip="Get directions"
          matTooltipPosition="above"
          class="stuck-hide"
          (click)="onMapBtnClick()"
        >
          <mat-icon>location_on</mat-icon>
        </button>
      }
    </div>

    <div class="date-container gsap-flip">
      <div class="calendar-container">
        <ark-calendar-icon [date]="dateStart()"></ark-calendar-icon>
      </div>
      <div class="date-text">
        <h3 class="stuck-hide gsap-flip">{{ dateStart() | date: 'fullDate' }}</h3>
        <h3 class="stuck-show gsap-flip">{{ dateStart() | date: 'M/d/yy' }}</h3>
        <h4>
          {{ dateStart() | date: 'h:mm a' }}
          @if (isEventPast()) {
            <span class="past-event-label">(Past Event)</span>
          }
        </h4>
      </div>
      <gth-cal-link
        [config]="config()"
        [title]="title()"
        [location]="location()"
        [description]="description()"
        [eventId]="eventId()"
        [dateStart]="dateStart()"
        [dateEnd]="dateEnd()"
        matTooltip="Save to calendar"
        matTooltipPosition="above"
        class="stuck-hide"
      />
    </div>
  </div>

  @if (isRecurring) {
    <gth-recurring-info [eventItem]="eventItem()" />
  }

  <div class="bottom-container stuck-hide gsap-flip">
    <a class="host-container" [routerLink]="[APP_ROUTES.Profile, host().uid]">
      <h4 data-flip-id="host-text">HOST</h4>
      <div class="host">
        <ark-user-avatar [src]="host().photoURL"></ark-user-avatar>
        <div class="gsap-flip">
          <h3>{{ host().displayName }}</h3>
        </div>
      </div>
    </a>



    <h3 class="participants">
      <mat-icon>people</mat-icon>
      <span>
        {{ participantCount() }}
        <span>{{ participantCount() | participantAmount }}</span>
      </span>
    </h3>
  </div>

  @if (skillLevels()?.length) {
  <mat-card class="skill-level">
    <mat-card-header>Skill Level</mat-card-header>
    <mat-card-content>
      <h4>{{ skillLevels().join(', ') }}</h4>
    </mat-card-content>
  </mat-card>
  }

  <gth-tab-header class="gsap-flip"></gth-tab-header>
  @if (!isEventPast()) {
  <gth-rsvp-bar
    [cost]="cost()"
    [hasRSVPed]="hasRSVPed()"
    class="gsap-flip"
  ></gth-rsvp-bar>
  } @else {
    <gth-event-passed-tab></gth-event-passed-tab>
  }
</div>
