import { Component, computed, inject, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { RecurringEventsService } from '@gth-legacy/services/recurring-events.service';
import { GthEventItemModel } from '@sentinels/models';

import { CalendarComponentModule } from '../../../../../../../gth/src/app/features/settings/availability/calendar/calendar.module';
import { CalendarDialogComponent } from './calendar-dialog/calendar-dialog.component';

@Component({
  selector: 'gth-recurring-info',
  standalone: true,
  imports: [
    MatButtonModule,
    CalendarComponentModule,
  ],
  templateUrl: './recurring-info.component.html',
  styleUrl: './recurring-info.component.scss',
})
export class RecurringInfoComponent {
  eventItem = input.required<GthEventItemModel>();

  recurringEventsService = inject(RecurringEventsService);

  constructor(private dialog: MatDialog) {}

  eventItemWithRecurringEvents = computed<GthEventItemModel[]>(() => {
    if (!this.eventItem()) return;

    const events = [
      ...this.recurringEventsService.buildRecurringEventItems([this.eventItem()]),
    ];
    return events;
  });

  openCalendar() {
    const dialogRef = this.dialog.open(CalendarDialogComponent, {
      data: {
        eventItems: this.eventItemWithRecurringEvents(),
      },
    });
  }
}
