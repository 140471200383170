import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterLink } from '@angular/router';
import { APP_ROUTES } from '@shared/helpers';

@Component({
  selector: 'gth-dupr-required-dialog',
  templateUrl: './dupr-required-dialog.component.html',
  styleUrl: './dupr-required-dialog.component.scss',
  standalone: true,
  imports: [MatDialogModule, RouterLink, MatButtonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DuprRequiredDialogComponent {
  protected readonly APP_ROUTES = APP_ROUTES;

  protected readonly duprSignUpUrl = 'https://dashboard.dupr.com/signup';
}
