@defer (when isEventReady()) {
  @if (eventViewModel(); as vm) {
    @if (preview()) {
      <div class="edit-banner fx-row">
        <span>You are currently viewing a preview of this event</span>
        <div>
          <button
            mat-stroked-button
            color="accent"
            class="uppercase action-btn"
            (click)="onBackButtonClick()"
          >
            Back to Edit
          </button>
          <button
            mat-stroked-button
            color="accent"
            class="uppercase action-btn"
            (click)="onSaveButtonClick()"
          >
            Save Event
          </button>
        </div>
      </div>
    }
    @if (shouldShow.manageAndEditButton) {
      <div class="edit-banner fx-row">
        <span>You can manage this event.</span>
        <div>
          <button
            mat-stroked-button
            color="accent"
            class="uppercase action-btn"
            (click)="onManageButtonClick(vm.event, vm.team, vm.canEditEvent)"
          >
            Manage
          </button>
          <button
            mat-stroked-button
            color="accent"
            class="uppercase action-btn"
            (click)="onCopyBtnClick(vm.event)"
          >
            Copy
          </button>
          <button
            mat-stroked-button
            color="warn"
            class="action-btn uppercase"
            (click)="onCancelButtonClick(vm.event, vm.canEditEvent)"
          >
            Cancel
          </button>
        </div>
      </div>
    }
    <gth-tab-group #tabGroup (selectedTabChange)="onSelectedTabChange()">
      <div class="event-info-container">
        @defer (when !!bannerImg()) {
          <picture class="banner-container">
            <div>
              @if (!preview()) {
                <button mat-mini-fab class="back-btn" (click)="onBackClick()">
                  <mat-icon fontIcon="arrow_back" />
                </button>
              }
            </div>
            @if (bannerImg().imageKit) {
              <img class="banner-image" [ngSrc]="bannerImg().src" [fill]="true" priority />
              <img class="banner-underlay" [ngSrc]="bannerImg().src" [fill]="true" loading="lazy"/>
            } @else {
              <img class="banner-image" [src]="bannerImg().src" priority />
              <img class="banner-underlay" [src]="bannerImg().src" loading="lazy"/>
            }
            <button
              mat-mini-fab
              class="favorite-btn"
              [matTooltip]="
              (isFavorited() ? 'Remove event from' : 'Add event to') + ' favorites'
            "
              (click)="onFavoriteClick(vm.event)"
            >
              <mat-icon [fontIcon]="isFavorited() ? 'favorite' : 'favorite_outline'" />
            </button>
          </picture>
        } @placeholder(minimum 500ms){
          <ngx-skeleton-loader
            [theme]="{
          'min-width': '100%',
          'height': '100% !important',
          'margin-bottom': '20px',
          'opacity': '50%',
          'display': 'flex',
          'align-items': 'center',
          'justify-content': 'center',
          'position': 'relative',
          'min-height': '200px',
          'max-height': '100%',
          'width': '100%',
          'overflow': 'hidden',
          'aspect-ratio': '2 / 1'
        }"
            animation="pulse"></ngx-skeleton-loader>
        }
        <gth-event-main-info
          #eventMainInfo
          [title]="vm.event.title"
          [description]="vm.event.description"
          [type]="vm.eventType"
          [address]="vm.address"
          [location]="vm.event.location"
          [dateStart]="eventDateStart()"
          [dateEnd]="eventDateEnd()"
          [online]="vm.event.online"
          [host]="preview() ? user() : vm.event.creator"
          [participantCount]="participantCount()"
          [cost]="vm.cost"
          [config]="config"
          [hasRSVPed]="hasRSVPed()"
          [eventId]="vm.event.id"
          [skillLevels]="vm.event.skillLevels"
          [recurring]="vm.event.recurring"
          [eventItem]="vm.event"
          (scrollToBody)="scrollToEventBody()"
        ></gth-event-main-info>
      </div>

      <div class="event-body-container">
        <gth-tab-header></gth-tab-header>

        <gth-tab-body #eventBody>
          <gth-tab name="info" label="More Info" icon="info">
            <gth-more-info-tab
              [vm]="vm"
              [user]="user()"
              [url]="url()"
              [preview]="preview()"
              [appName]="config.appName"
              [showCost]="shouldShow.cost"
              [participants]="participants()"
            ></gth-more-info-tab>
          </gth-tab>
          <gth-tab name="location" label="Location" icon="map">
            <gth-location-tab
              [latitude]="vm.lat"
              [longitude]="vm.lng"
              [isOnline]="!!vm.event.online"
            ></gth-location-tab>
          </gth-tab>
          @if (!isEventPast()) {
            <gth-tab name="rsvp" label="RSVP" icon="person_add">
              <gth-rsvp-tab
                [vm]="vm"
                [isGuestProfile]="isGuestProfile()"
                [user]="user()"
                [preview]="preview()"
                [hasRSVPed]="hasRSVPed()"
                [isBasic]="vm.event.isBasic"
                [recurDate]="dateParam()"
                (joinEvent)="onJoinEvent($event)"
                (loginClick)="onLoginClick(vm.event)"
                [checkoutSessionLoading]="checkoutSessionLoading()"
              ></gth-rsvp-tab>
            </gth-tab>
          }
          <gth-tab name="chat" label="Chat" icon="chat" [disabled]="true">
            <gth-chat-tab [eventId]="vm.event.id"></gth-chat-tab>
          </gth-tab>
        </gth-tab-body>
      </div>
      <gth-rsvp-bar
        [hasRSVPed]="hasRSVPed()"
        [cost]="vm.cost"
        [class.collapsed]="isBottomNavCollapsed()"
      ></gth-rsvp-bar>
    </gth-tab-group>
  }
} @placeholder {
  loading event info...
}
