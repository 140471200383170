import firebase from 'firebase/compat/app';

import { EventRsvpStatus } from '../interfaces/event-item';
import { Mapper } from '../interfaces/mapper';
import { EventJoinerModel } from '../models/event-joiner';
import { DBUtil } from '../utils/db-utils';
import { firestorePopulateSnapshot } from '../utils/populaters';
import { UserMapper } from './user-mapper';

export class EventJoinerMapper implements Mapper<EventJoinerModel> {
  fromSnapshot(snapshot: firebase.firestore.DocumentSnapshot): EventJoinerModel | undefined {
    if (snapshot === null || snapshot === undefined) return undefined;
    const data = snapshot.data();
    if (data === null || data === undefined) return undefined;

    return new EventJoinerModel(
      snapshot.ref as any,
      data[EventJoinerModel.PLAYER],
      data[EventJoinerModel.STATUS],
      data[EventJoinerModel.ASSIGNED_GENDER_SPOT],
      data[EventJoinerModel.APPROVED_BY],
      data[EventJoinerModel.GUESTS],
      data[EventJoinerModel.RSVP_STATUS],
      data[EventJoinerModel.CREATED_AT],
      data[EventJoinerModel.IS_GUEST_USER],
      data[EventJoinerModel.SLOT],
      data[EventJoinerModel.SLOT_NUMBER],
      data[EventJoinerModel.GUEST_OF_PARTICIPANT],
      data[EventJoinerModel.SURVEY_ANSWERS],
      data[EventJoinerModel.EVENT_TEAMS_ONLY],
      data[EventJoinerModel.GUEST_NAME],
      data[EventJoinerModel.GUEST_EMAIL],
      data[EventJoinerModel.GROUP_NAME],
      data[EventJoinerModel.ATTENDANCE_STATUS],
    );
  }

  async fromSnapshotPopulated(
    snapshot: firebase.firestore.DocumentSnapshot,
  ): Promise<EventJoinerModel | undefined> {
    const model = this.fromSnapshot(snapshot);
    if (!model) {
      throw new Error('No Model in Event Joiner Model');
    }

    if (!model.ref) return;

    const data = await firestorePopulateSnapshot(model.ref, DBUtil.User, model.player);

    const userModel = new UserMapper().fromSnapshot(data)!;
    return new EventJoinerModel(
      model.ref,
      userModel.id || '',
      model.status,
      model.assignedGenderSpot,
      model.approvedBy,
      model.guests,
      model.rsvpStatus,
      model.createdAt,
      model.isGuestUser,
      model.isUnregisteredUser,
      model.slotName,
      model.slotNumber,
      model.guestOfParticipant,
      model.surveyAnswers,
      model.eventTeamsOnly,
      model.guestName,
      model.guestEmail,
      model.groupName,
      model.attendanceStatus,
    );
  }

  toMap(item: EventJoinerModel): firebase.firestore.DocumentData {
    const playerId = item.player;
    const mapped = {
      [EventJoinerModel.PLAYER]: playerId,
      [EventJoinerModel.STATUS]: item.status,
      [EventJoinerModel.ASSIGNED_GENDER_SPOT]: item.assignedGenderSpot,
      [EventJoinerModel.APPROVED_BY]: item.approvedBy,
      [EventJoinerModel.GUESTS]: item.guests,
      [EventJoinerModel.RSVP_STATUS]: item.rsvpStatus ?? EventRsvpStatus.NOT_PLAYING,
      [EventJoinerModel.CREATED_AT]: item.createdAt,
      [EventJoinerModel.IS_GUEST_USER]: item.isGuestUser ?? false,
      [EventJoinerModel.SLOT]: item.slotName,
      [EventJoinerModel.GUEST_OF_PARTICIPANT]: item.guestOfParticipant ?? false,
      [EventJoinerModel.SURVEY_ANSWERS]: item.surveyAnswers,
      [EventJoinerModel.EVENT_TEAMS_ONLY]: item.eventTeamsOnly,
      [EventJoinerModel.GUEST_NAME]: item.guestName,
      [EventJoinerModel.GUEST_EMAIL]: item.guestEmail,
      [EventJoinerModel.GROUP_NAME]: item.groupName,
      [EventJoinerModel.ATTENDANCE_STATUS]: item.attendanceStatus,
    };
    return mapped;
  }
}
