import { Component } from '@angular/core';

@Component({
  selector: 'gth-event-passed-tab',
  standalone: true,
  imports: [],
  templateUrl: './event-passed-tab.component.html',
  styleUrl: './event-passed-tab.component.scss',
})
export class EventPassedTabComponent {

}
